import Vue from 'vue'
import VueRouter from 'vue-router'
import Layouts from '@/layouts'
Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: 'home',
        component: Layouts,
        children: [
            {
                path: '/home',
                meta: { title: 'Home', icon: '' },
                component: () => import('@/views/home'),
            },
            {
                path: '/Statistics',
                meta: { title: 'Statistics', icon: '' },
                component: () => import('@/views/Statistics'),
            },
            {
                path: '/Referral',
                meta: { title: 'Referral', icon: '' },
                component: () => import('@/views/Referral'),
            },
            {
                path: '/UserReferral',
                meta: { title: 'UserReferral', icon: '' },
                component: () => import('@/views/UserReferral'),
            },
            {
                path: '/Task',
                meta: { title: 'Task', icon: '' },
                component: () => import('@/views/Task'),
            },
            {
                path: '/withdrawalManagement',
                meta: { title: 'withdrawalManagement', icon: '' },
                component: () => import('@/views/withdrawalManagement'),
            },
            {
                path: '/orderList',
                meta: { title: 'orderList', icon: '', notShow: true },
                component: () => import('@/views/orderList'),

            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})
const whiteList = ['/login']
let name = localStorage.getItem('account')
if (name == 'root' || name == 'lx' || name == 'boss' || name == '15280401179') {
    router.options.routes[0].children.forEach(item => {
        if (item.meta.title == 'orderList') {
            item.meta.notShow = false
        }
    });
    // console.log("99999999999999999999999999", router);
}
// 前置路由拦截器
router.beforeEach((to, from, next) => {

    // 设置当前页签名称
    document.title = to.name || process.env.VUE_APP_TITLE
    if (whiteList.indexOf(to.path) !== -1) {
        next()
    } else {
        if (localStorage.getItem('token')) {
            next()
        } else {
            next('/login')
        }
    }
})

export default router
